var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("q-form", { ref: "editForm" }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            {
              staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
            },
            [
              _c(
                "c-card",
                {
                  staticClass: "cardClassDetailForm",
                  attrs: { title: "기본정보" },
                },
                [
                  _c("template", { slot: "card-detail" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
                      },
                      [
                        _c("c-plant", {
                          attrs: {
                            required: "",
                            editable: _vm.editable,
                            disabled: _vm.disabled,
                            type: "edit",
                            name: "plantCd",
                          },
                          model: {
                            value: _vm.tbmData.plantCd,
                            callback: function ($$v) {
                              _vm.$set(_vm.tbmData, "plantCd", $$v)
                            },
                            expression: "tbmData.plantCd",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
                      },
                      [
                        _c("c-process", {
                          attrs: {
                            editable: _vm.editable,
                            disabled: _vm.disabled,
                            label: "공정",
                            name: "processCd",
                          },
                          model: {
                            value: _vm.tbmData.processCd,
                            callback: function ($$v) {
                              _vm.$set(_vm.tbmData, "processCd", $$v)
                            },
                            expression: "tbmData.processCd",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
                      },
                      [
                        _c("c-vendor", {
                          attrs: {
                            editable: _vm.editable,
                            disabled: _vm.disabled,
                            haveProcess: true,
                            label: "업체",
                            name: "vendorCd",
                          },
                          model: {
                            value: _vm.tbmData.vendorCd,
                            callback: function ($$v) {
                              _vm.$set(_vm.tbmData, "vendorCd", $$v)
                            },
                            expression: "tbmData.vendorCd",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
                      },
                      [
                        _c("c-work-permit", {
                          attrs: {
                            editable: _vm.editable,
                            disabled: _vm.disabled,
                            isRemoveConfirm: true,
                            label: "작업허가번호 | 작업일",
                            name: "sopWorkPermitId",
                          },
                          on: {
                            input: _vm.input,
                            remove: _vm.removeWorkPermit,
                          },
                          model: {
                            value: _vm.tbmData.sopWorkPermitId,
                            callback: function ($$v) {
                              _vm.$set(_vm.tbmData, "sopWorkPermitId", $$v)
                            },
                            expression: "tbmData.sopWorkPermitId",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
                      },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            disabled: true,
                            label: "TBM번호",
                            name: "tbmNo",
                          },
                          model: {
                            value: _vm.tbmData.tbmNo,
                            callback: function ($$v) {
                              _vm.$set(_vm.tbmData, "tbmNo", $$v)
                            },
                            expression: "tbmData.tbmNo",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
                      },
                      [
                        _c("c-text", {
                          attrs: {
                            required: "",
                            editable: _vm.editable,
                            disabled: _vm.disabled,
                            label: "TBM명",
                            name: "tbmName",
                          },
                          model: {
                            value: _vm.tbmData.tbmName,
                            callback: function ($$v) {
                              _vm.$set(_vm.tbmData, "tbmName", $$v)
                            },
                            expression: "tbmData.tbmName",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6",
                      },
                      [
                        _c("c-datepicker", {
                          attrs: {
                            required: "",
                            editable: _vm.editable,
                            disabled: _vm.disabled,
                            label: "TBM일자",
                            type: "date",
                            name: "tbmDate",
                          },
                          model: {
                            value: _vm.tbmData.tbmDate,
                            callback: function ($$v) {
                              _vm.$set(_vm.tbmData, "tbmDate", $$v)
                            },
                            expression: "tbmData.tbmDate",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6",
                      },
                      [
                        _c("c-datepicker", {
                          attrs: {
                            required: true,
                            disabled: _vm.disabled,
                            editable: _vm.editable,
                            range: true,
                            minuteStep: 10,
                            type: "time",
                            label: "TBM시간",
                            name: "workTime",
                          },
                          model: {
                            value: _vm.workTime,
                            callback: function ($$v) {
                              _vm.workTime = $$v
                            },
                            expression: "workTime",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
                      },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            disabled: _vm.disabled,
                            label: "TBM장소",
                            name: "tbmLocation",
                          },
                          model: {
                            value: _vm.tbmData.tbmLocation,
                            callback: function ($$v) {
                              _vm.$set(_vm.tbmData, "tbmLocation", $$v)
                            },
                            expression: "tbmData.tbmLocation",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
                      },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            disabled: _vm.disabled,
                            label: "작업",
                            name: "tbmName",
                          },
                          model: {
                            value: _vm.tbmData.tbmName,
                            callback: function ($$v) {
                              _vm.$set(_vm.tbmData, "tbmName", $$v)
                            },
                            expression: "tbmData.tbmName",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
                      },
                      [
                        _c("c-field", {
                          attrs: {
                            editable: _vm.editable,
                            disabled: _vm.disabled,
                            type: "dept_job_user",
                            label: "TBM리더",
                            name: "tbmLeaderId",
                          },
                          model: {
                            value: _vm.tbmData.tbmLeaderId,
                            callback: function ($$v) {
                              _vm.$set(_vm.tbmData, "tbmLeaderId", $$v)
                            },
                            expression: "tbmData.tbmLeaderId",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
                      },
                      [
                        _c("c-textarea", {
                          attrs: {
                            editable: _vm.editable,
                            disabled: _vm.disabled,
                            label: "작업내용",
                            name: "tbmContents",
                          },
                          model: {
                            value: _vm.tbmData.tbmContents,
                            callback: function ($$v) {
                              _vm.$set(_vm.tbmData, "tbmContents", $$v)
                            },
                            expression: "tbmData.tbmContents",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
                      },
                      [
                        _c("c-textarea", {
                          attrs: {
                            editable: _vm.editable,
                            disabled: _vm.disabled,
                            label: "작업 전 일일 안전점검 시행 결과",
                            name: "preInspectionResult",
                          },
                          model: {
                            value: _vm.tbmData.preInspectionResult,
                            callback: function ($$v) {
                              _vm.$set(_vm.tbmData, "preInspectionResult", $$v)
                            },
                            expression: "tbmData.preInspectionResult",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
                      },
                      [
                        _c("c-textarea", {
                          attrs: {
                            editable: _vm.editable,
                            disabled: _vm.disabled,
                            label: "작업 후 종료 미팅 (중점대책의 실효성)",
                            name: "tbmResult",
                          },
                          model: {
                            value: _vm.tbmData.tbmResult,
                            callback: function ($$v) {
                              _vm.$set(_vm.tbmData, "tbmResult", $$v)
                            },
                            expression: "tbmData.tbmResult",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" },
            [
              _c(
                "c-table",
                {
                  ref: "riskTable",
                  attrs: {
                    title: "위험요인 목록",
                    columns: _vm.riskGrid.columns,
                    data: _vm.tbmData.tbmRiskList,
                    isTitle: true,
                    filtering: false,
                    columnSetting: false,
                    usePaging: false,
                    noDataLabel: "위험요인을 추가하세요.",
                    hideBottom: false,
                    editable: _vm.editable && !_vm.disabled,
                    rowKey: "tbmRiskId",
                    selection: "multiple",
                    isFullScreen: false,
                    isExcelDown: false,
                    gridHeightAuto: true,
                  },
                  on: { "table-data-change": _vm.tableDataChange },
                  scopedSlots: _vm._u([
                    {
                      key: "customArea",
                      fn: function ({ props, col }) {
                        return [
                          col.name === "tbmRisk"
                            ? [
                                props.row["tbmRiskType"] === "직접추가"
                                  ? _c("c-text-column", {
                                      attrs: {
                                        editable: _vm.editable,
                                        props: props,
                                        col: col,
                                        disabled: _vm.disabled,
                                      },
                                      on: {
                                        datachange1: function ($event) {
                                          return _vm.datachange1(props)
                                        },
                                      },
                                      model: {
                                        value: props.row["tbmRisk"],
                                        callback: function ($$v) {
                                          _vm.$set(props.row, "tbmRisk", $$v)
                                        },
                                        expression: "props.row['tbmRisk']",
                                      },
                                    })
                                  : _c("span", [
                                      _vm._v(
                                        " " + _vm._s(props.row["tbmRisk"]) + " "
                                      ),
                                    ]),
                              ]
                            : _vm._e(),
                        ]
                      },
                    },
                  ]),
                },
                [
                  _c(
                    "template",
                    { slot: "table-button" },
                    [
                      _c(
                        "q-btn-group",
                        { attrs: { outline: "" } },
                        [
                          _vm.editable && !_vm.disabled
                            ? _c("c-btn", {
                                attrs: {
                                  showLoading: false,
                                  label: "3단계판단법",
                                  icon: "add",
                                },
                                on: { btnClicked: _vm.add3rdRisk },
                              })
                            : _vm._e(),
                          _vm.editable && !_vm.disabled
                            ? _c("c-btn", {
                                attrs: {
                                  showLoading: false,
                                  label: "4M",
                                  icon: "add",
                                },
                                on: { btnClicked: _vm.add4MRisk },
                              })
                            : _vm._e(),
                          _vm.editable && !_vm.disabled
                            ? _c("c-btn", {
                                attrs: {
                                  showLoading: false,
                                  label: "JRA",
                                  icon: "add",
                                },
                                on: { btnClicked: _vm.addJRARisk },
                              })
                            : _vm._e(),
                          _vm.editable && !_vm.disabled
                            ? _c("c-btn", {
                                attrs: {
                                  showLoading: false,
                                  label: "직접",
                                  icon: "add",
                                },
                                on: { btnClicked: _vm.addRisk },
                              })
                            : _vm._e(),
                          _vm.editable &&
                          !_vm.disabled &&
                          _vm.tbmData.tbmRiskList.length > 0
                            ? _c("c-btn", {
                                attrs: {
                                  showLoading: false,
                                  label: "",
                                  icon: "remove",
                                },
                                on: { btnClicked: _vm.removeRisk },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
            },
            [
              _c(
                "c-table",
                {
                  ref: "attendeeTable",
                  attrs: {
                    title: "참석자 목록",
                    columns: _vm.attendeeGrid.columns,
                    data: _vm.tbmData.tbmAttendeeList,
                    isTitle: true,
                    filtering: false,
                    columnSetting: false,
                    usePaging: false,
                    noDataLabel: "참석자가 없습니다.",
                    gridHeightAuto: true,
                    hideBottom: false,
                    editable: _vm.editable && !_vm.disabled,
                    selection: "multiple",
                    rowKey: "tbmAttendeeId",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "customArea",
                      fn: function ({ props, col }) {
                        return [
                          col.name === "tbmAttendeeDept"
                            ? [
                                props.row["attendeeType"] === "직접추가"
                                  ? _c("c-text-column", {
                                      attrs: {
                                        editable: _vm.editable,
                                        props: props,
                                        col: col,
                                        disabled: _vm.disabled,
                                      },
                                      on: {
                                        datachange: function ($event) {
                                          return _vm.datachange(props)
                                        },
                                      },
                                      model: {
                                        value: props.row["tbmAttendeeDept"],
                                        callback: function ($$v) {
                                          _vm.$set(
                                            props.row,
                                            "tbmAttendeeDept",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "props.row['tbmAttendeeDept']",
                                      },
                                    })
                                  : _c("span", [
                                      _vm._v(
                                        " " +
                                          _vm._s(props.row["tbmAttendeeDept"]) +
                                          " "
                                      ),
                                    ]),
                              ]
                            : _vm._e(),
                          col.name === "tbmAttendeeJob"
                            ? [
                                props.row["attendeeType"] === "직접추가"
                                  ? _c("c-text-column", {
                                      attrs: {
                                        editable: _vm.editable,
                                        props: props,
                                        col: col,
                                        disabled: _vm.disabled,
                                      },
                                      on: {
                                        datachange: function ($event) {
                                          return _vm.datachange(props)
                                        },
                                      },
                                      model: {
                                        value: props.row["tbmAttendeeJob"],
                                        callback: function ($$v) {
                                          _vm.$set(
                                            props.row,
                                            "tbmAttendeeJob",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "props.row['tbmAttendeeJob']",
                                      },
                                    })
                                  : _c("span", [
                                      _vm._v(
                                        " " +
                                          _vm._s(props.row["tbmAttendeeJob"]) +
                                          " "
                                      ),
                                    ]),
                              ]
                            : _vm._e(),
                          col.name === "tbmAttendeeName"
                            ? [
                                props.row["attendeeType"] === "직접추가"
                                  ? _c("c-text-column", {
                                      attrs: {
                                        editable: _vm.editable,
                                        props: props,
                                        col: col,
                                        disabled: _vm.disabled,
                                      },
                                      on: {
                                        datachange: function ($event) {
                                          return _vm.datachange(props)
                                        },
                                      },
                                      model: {
                                        value: props.row["tbmAttendeeName"],
                                        callback: function ($$v) {
                                          _vm.$set(
                                            props.row,
                                            "tbmAttendeeName",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "props.row['tbmAttendeeName']",
                                      },
                                    })
                                  : _c("span", [
                                      _vm._v(
                                        " " +
                                          _vm._s(props.row["tbmAttendeeName"]) +
                                          " "
                                      ),
                                    ]),
                              ]
                            : _vm._e(),
                          col.name === "click"
                            ? [
                                props.row["attendeeType"] !== "직접추가"
                                  ? _c(
                                      "q-btn",
                                      {
                                        attrs: {
                                          round: "",
                                          unelevated: "",
                                          size: "xs",
                                          color: "amber",
                                          icon: "mode",
                                        },
                                        on: {
                                          click: function ($event) {
                                            $event.stopPropagation()
                                            return (() => {
                                              ;(_vm.rowIndex = props.rowIndex),
                                                (_vm.attendeeSign =
                                                  props.row.attendeeSign)
                                            }).apply(null, arguments)
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "q-popup-proxy",
                                          {
                                            ref: "proxy_" + props.rowIndex,
                                            attrs: { breakpoint: 400 },
                                          },
                                          [
                                            _c(col.component, {
                                              tag: "component",
                                              attrs: {
                                                outFlag: true,
                                                popupParam: props.row,
                                                data: _vm.tbmData,
                                                rowIndex: props.rowIndex,
                                              },
                                              on: {
                                                attendeeSignCallback:
                                                  _vm.attendeeSignCallback,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ]
                            : _vm._e(),
                          col.name === "customCol"
                            ? [
                                _c(_vm.detailComponent, {
                                  tag: "component",
                                  attrs: {
                                    col: col,
                                    props: props,
                                    rowIndex: props.rowIndex,
                                    tableKey: "tbmAttendeeId",
                                  },
                                  on: {
                                    closeAttendeeDetail:
                                      _vm.closeAttendeeDetail,
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "customArea",
                                        fn: function ({ props }) {
                                          return [
                                            _c(
                                              "q-form",
                                              { ref: "editForm2" },
                                              [
                                                _c(
                                                  "c-card",
                                                  {
                                                    staticClass:
                                                      "cardClassDetailForm q-mb-sm",
                                                    attrs: {
                                                      title: "참석자 세부정보",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "template",
                                                      { slot: "card-detail" },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6",
                                                          },
                                                          [
                                                            _c("c-text", {
                                                              attrs: {
                                                                disabled:
                                                                  _vm.popupParam
                                                                    .disabled ||
                                                                  props.row[
                                                                    "attendeeType"
                                                                  ] !==
                                                                    "직접추가",
                                                                editable:
                                                                  _vm.editable,
                                                                label: "참석자",
                                                                name: "tbmAttendeeName",
                                                              },
                                                              model: {
                                                                value:
                                                                  props.row
                                                                    .tbmAttendeeName,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      props.row,
                                                                      "tbmAttendeeName",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "props.row.tbmAttendeeName",
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6",
                                                          },
                                                          [
                                                            _c("c-text", {
                                                              attrs: {
                                                                disabled:
                                                                  _vm.popupParam
                                                                    .disabled ||
                                                                  props.row[
                                                                    "attendeeType"
                                                                  ] !==
                                                                    "직접추가",
                                                                editable:
                                                                  _vm.editable,
                                                                label: "직책",
                                                                name: "tbmAttendeeJob",
                                                              },
                                                              model: {
                                                                value:
                                                                  props.row
                                                                    .tbmAttendeeJob,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      props.row,
                                                                      "tbmAttendeeJob",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "props.row.tbmAttendeeJob",
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "col-12",
                                                          },
                                                          [
                                                            _c("c-textarea", {
                                                              attrs: {
                                                                disabled:
                                                                  _vm.popupParam
                                                                    .disabled,
                                                                editable:
                                                                  _vm.editable,
                                                                rows: 2,
                                                                label:
                                                                  "참석자 비고",
                                                                name: "remark",
                                                              },
                                                              model: {
                                                                value:
                                                                  props.row
                                                                    .remark,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      props.row,
                                                                      "remark",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "props.row.remark",
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "col-xs-3 col-sm-3 col-md-2 col-lg-2 col-xl-2",
                                                          },
                                                          [
                                                            _c("c-checkbox", {
                                                              attrs: {
                                                                disabled:
                                                                  _vm.popupParam
                                                                    .disabled,
                                                                isFlag: true,
                                                                label:
                                                                  "정신적피로",
                                                                name: "mentalFlag",
                                                              },
                                                              model: {
                                                                value:
                                                                  props.row
                                                                    .mentalFlag,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      props.row,
                                                                      "mentalFlag",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "props.row.mentalFlag",
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "col-xs-3 col-sm-3 col-md-2 col-lg-2 col-xl-2",
                                                          },
                                                          [
                                                            _c("c-checkbox", {
                                                              attrs: {
                                                                disabled:
                                                                  _vm.popupParam
                                                                    .disabled,
                                                                isFlag: true,
                                                                label:
                                                                  "심리적피로",
                                                                name: "phychologicalFlag",
                                                              },
                                                              model: {
                                                                value:
                                                                  props.row
                                                                    .phychologicalFlag,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      props.row,
                                                                      "phychologicalFlag",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "props.row.phychologicalFlag",
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "col-xs-3 col-sm-3 col-md-2 col-lg-2 col-xl-2",
                                                          },
                                                          [
                                                            _c("c-checkbox", {
                                                              attrs: {
                                                                disabled:
                                                                  _vm.popupParam
                                                                    .disabled,
                                                                isFlag: true,
                                                                label:
                                                                  "육체적피로",
                                                                name: "physicalFlag",
                                                              },
                                                              model: {
                                                                value:
                                                                  props.row
                                                                    .physicalFlag,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      props.row,
                                                                      "physicalFlag",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "props.row.physicalFlag",
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "col-xs-3 col-sm-3 col-md-2 col-lg-2 col-xl-2",
                                                          },
                                                          [
                                                            _c("c-checkbox", {
                                                              attrs: {
                                                                disabled:
                                                                  _vm.popupParam
                                                                    .disabled,
                                                                isFlag: true,
                                                                label:
                                                                  "신체적결함",
                                                                name: "physicalDefectFlag",
                                                              },
                                                              model: {
                                                                value:
                                                                  props.row
                                                                    .physicalDefectFlag,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      props.row,
                                                                      "physicalDefectFlag",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "props.row.physicalDefectFlag",
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6",
                                                          },
                                                          [
                                                            _c("c-select", {
                                                              attrs: {
                                                                disabled:
                                                                  _vm.popupParam
                                                                    .disabled,
                                                                codeGroupCd:
                                                                  "TBM_HEALTH_STATUS_CD",
                                                                type: "search",
                                                                itemText:
                                                                  "codeName",
                                                                itemValue:
                                                                  "code",
                                                                name: "healthStatusCd",
                                                                label:
                                                                  "건강상태",
                                                              },
                                                              model: {
                                                                value:
                                                                  props.row
                                                                    .healthStatusCd,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      props.row,
                                                                      "healthStatusCd",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "props.row.healthStatusCd",
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  2
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                              ]
                            : _vm._e(),
                        ]
                      },
                    },
                  ]),
                },
                [
                  _c(
                    "template",
                    { slot: "table-button" },
                    [
                      _c(
                        "q-btn-group",
                        { attrs: { outline: "" } },
                        [
                          _vm.editable && !_vm.disabled
                            ? _c("c-btn", {
                                attrs: {
                                  showLoading: false,
                                  label: "협력",
                                  icon: "add",
                                },
                                on: { btnClicked: _vm.addAttendeePartner },
                              })
                            : _vm._e(),
                          _vm.editable && !_vm.disabled
                            ? _c("c-btn", {
                                attrs: {
                                  showLoading: false,
                                  label: "현업",
                                  icon: "add",
                                },
                                on: { btnClicked: _vm.addAttendee },
                              })
                            : _vm._e(),
                          _vm.editable && !_vm.disabled
                            ? _c("c-btn", {
                                attrs: {
                                  showLoading: false,
                                  label: "직접",
                                  icon: "add",
                                },
                                on: { btnClicked: _vm.addAttendeeDirect },
                              })
                            : _vm._e(),
                          _vm.editable &&
                          !_vm.disabled &&
                          _vm.tbmData.tbmAttendeeList.length > 0
                            ? _c("c-btn", {
                                attrs: {
                                  showLoading: false,
                                  label: "",
                                  icon: "remove",
                                },
                                on: { btnClicked: _vm.removeAttendee },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-12" },
            [
              _c("c-upload", {
                attrs: {
                  attachInfo: _vm.attachInfo,
                  editable: _vm.editable && !_vm.disabled,
                  label: "교육자료",
                },
              }),
            ],
            1
          ),
        ]),
      ]),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
      _c("div", { staticClass: "popup-bottom-bar" }, [
        _c(
          "div",
          { staticClass: "popup-bottom-bar-btngroup" },
          [
            _c(
              "q-btn-group",
              { attrs: { outline: "" } },
              [
                _c("c-btn", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.editable && !_vm.popupParam.tbmId,
                      expression: "editable && !popupParam.tbmId",
                    },
                  ],
                  attrs: { flat: true, label: "불러오기", icon: "save_alt" },
                  on: { btnClicked: _vm.copyTbm },
                }),
                _c("c-btn", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        _vm.editable && _vm.popupParam.tbmId && !_vm.disabled,
                      expression: "editable && popupParam.tbmId && !disabled",
                    },
                  ],
                  attrs: {
                    flat: true,
                    label: "삭제",
                    editable: _vm.editable,
                    icon: "delete_forever",
                  },
                  on: { btnClicked: _vm.remove },
                }),
                _c("c-btn", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.editable && !_vm.disabled,
                      expression: "editable && !disabled",
                    },
                  ],
                  attrs: {
                    flat: true,
                    url: _vm.saveUrl,
                    isSubmit: _vm.isSave,
                    param: _vm.tbmData,
                    mappingType: _vm.saveType,
                    label: "저장",
                    icon: "save",
                  },
                  on: {
                    beforeAction: _vm.saveTbm,
                    btnCallback: _vm.saveCallback,
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "popup-bottom-bar-close" },
          [
            _c("q-btn", {
              attrs: { flat: "", color: "gray", icon: "arrow_back" },
              on: { click: _vm.closePopUps },
            }),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }